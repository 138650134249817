import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

import { cls } from '@/utils'

const badgeVariants = cva(
  cls(
    'inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  ),
  {
    variants: {
      variant: {
        default: cls(
          'border-transparent bg-primary text-text-interactive hover:bg-surface-primary-hover active:bg-surface-primary-hover',
        ),
        secondary: cls(
          'border-transparent bg-surface-subdued text-text-subdued hover:bg-secondary/80 active:bg-secondary/80',
        ),
        destructive: cls(
          'border-transparent bg-destructive text-text-critical hover:bg-destructive/80 active:bg-destructive/80',
        ),
        outline: cls('text-text'),
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cls(badgeVariants({ variant }), className)} {...props} />
  )
}

export { Badge, badgeVariants }
