import { cls } from '@/utils'
import { cva, type VariantProps } from 'class-variance-authority'

const containerVariants = cva('rounded-full border border-border shrink-0 flex items-center justify-center', {
  variants: {
    size: {
      lg: 'size-14',
      md: 'size-10',
      sm: 'size-8',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

const iconVariants = cva('shrink-0', {
  variants: {
    size: {
      md: 'size-6',
      lg: 'size-9',
      sm: 'size-5',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

export interface TemplateIconProps extends VariantProps<typeof containerVariants> {
  className?: string
  src: string | null | undefined
  alt?: string
  iconClassName?: string
}

export default function TemplateIcon({
  className,
  src,
  size,
  alt = 'Template Category Icon',
  iconClassName,
}: TemplateIconProps) {
  return (
    <div className={cls(containerVariants({ size }), className)}>
      <img src={src ?? ''} alt={alt} className={cls(iconVariants({ size }), iconClassName)} />
    </div>
  )
}
