import { DEFAULT_AVATAR } from '@/constants'
import { cls } from '@/utils'

export interface AvatarProps {
  src: string
  alt?: string
  className?: string
}

export default function Avatar({ src = DEFAULT_AVATAR, alt = 'Avatar', className }: AvatarProps) {
  return (
    <img
      className={cls('rounded-full object-cover size-6 shrink-0', className)}
      src={src || DEFAULT_AVATAR}
      alt={alt}
      onError={(e) => {
        e.currentTarget.src = DEFAULT_AVATAR
      }}
    />
  )
}
