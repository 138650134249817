import type {
  Collection,
  CollectionEditParam,
  Creation,
  PaginationQuery,
  Segment,
  SegmentQuery,
} from '@/types'
import { rest } from '@/utils'

export const getCollections = async (): Promise<Collection[]> => {
  const res = await rest.get('/v1/collections')
  return res.data
}

export const getUserProfileCollections = async (
  userId: string,
): Promise<Segment<Collection>> => {
  const res = await rest.get(`/v1/user/${userId}/collections`)
  return res.data
}

export const getCollectionsOfCreation = async (
  creationId: string,
): Promise<string[]> => {
  const res = await rest.get(`/v1/creation/${creationId}/collections`)
  return res.data
}

export const saveToCollection = async (data: {
  creation_id: string
  collection_ids: string[]
}): Promise<any> => {
  const res = await rest.put(`/v1/creation/${data.creation_id}/collections`, {
    collection_ids: data.collection_ids,
  })
  return res
}

export const createCollection = async (
  data: Omit<CollectionEditParam, 'collection_id'>,
): Promise<Collection> => {
  const res = await rest.post('/v1/collection', data)
  return res.data
}

export const updateCollection = async (data: Collection): Promise<null> => {
  const { collection_id, ...params } = data
  const res = await rest.put(`/v1/collection/${collection_id}`, params)
  return res.data
}

export const getCollectionInfo = async ({
  collectionId,
  userId,
}: {
  collectionId: string
  userId: string
}): Promise<Collection> => {
  const res = await rest.get(
    `/v1/user/${userId}/collection/${collectionId}/info`,
  )
  return res.data
}

export const getUserProfileCollectionTasks = async ({
  userId,
  collectionId,
  ...params
}: PaginationQuery<{
  collectionId: string
  userId: string
}>): Promise<Segment<Creation>> => {
  const res = await rest.get(
    `/v1/user/${userId}/collection/${collectionId}/data`,
    { params },
  )
  return res.data
}

export const deleteCollection = async (collectionId: string): Promise<any> => {
  const res = await rest.delete(`v1/collection/${collectionId}`)
  return res
}
