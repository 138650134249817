import { FC, useEffect, useState } from 'react'
import IconPlus from '@haiper/icons-svg/icons/outline/plus-small.svg'
import IconFolder from '@haiper/icons-svg/icons/outline/folder.svg'
import Dialog from '@/components/dialog'
import Button from '@/components/button'
import { cls } from '@/utils'
import { Checkbox } from '@/components/ui/checkbox'
import EditProjectDialog from '@/components/edit-collection-dialog'
import { useAtom } from 'jotai'
import { globalCollectionsAtom } from '@/atoms'
import { getCollections } from '@/service/collection.service'
import LoadingButton from '../loading-button'
import Loading from '../loading'

interface SelectCollectionDialogProps {
  open: boolean
  selectedKeys: string[]
  creationId: string
  loading?: boolean
  onKeysChange: (keys: string[]) => void
  onOpenChange: (v: boolean) => void
  onAddToCollection: (keys: string[]) => void
}

const SelectCollectionDialog = ({
  open,
  selectedKeys,
  onOpenChange,
  onAddToCollection,
  onKeysChange,
  loading,
}: SelectCollectionDialogProps) => {
  const [selectProjectOpen, setSelectProjectOpen] = useState(false)
  const [addProjectOpen, setAddProjectOpen] = useState(false)
  const [collectionList, setCollectionList] = useAtom(globalCollectionsAtom)

  const handleClick = (index: string) => {
    const findIndex = selectedKeys.indexOf(index)
    if (findIndex >= 0) {
      selectedKeys.splice(findIndex, 1)
      onKeysChange([...selectedKeys])
    } else {
      onKeysChange([...selectedKeys, index])
    }
  }

  const createSuccess = async (key?: string) => {
    const res = await getCollections()
    setCollectionList(res)
    if (key) {
      onKeysChange([...selectedKeys, key])
    }
    setSelectProjectOpen(true)
    setAddProjectOpen(false)
  }

  useEffect(() => {
    if (!collectionList.length && open) {
      setAddProjectOpen(true)
    } else if (open) {
      setSelectProjectOpen(true)
    } else {
      setSelectProjectOpen(open)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    if (!addProjectOpen && !collectionList.length) {
      onOpenChange(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addProjectOpen])

  return (
    <>
      <Dialog
        open={selectProjectOpen}
        title='Select collection'
        footer={
          <div className='flex gap-3 w-full'>
            <Button
              variant='outline'
              disabled={loading}
              className='flex-1'
              onClick={() => setAddProjectOpen(true)}
            >
              <IconPlus className='text-icon' />
              <span className='px-1 font-medium'>Create new collection</span>
            </Button>
            <LoadingButton
              variant='primary'
              disabled={loading}
              className='flex-1'
              onClick={async () => await onAddToCollection(selectedKeys)}
            >
              Save
            </LoadingButton>
          </div>
        }
        onOpenChange={onOpenChange}
      >
        <div className='max-h-[476px] flex flex-col gap-2 mt-6 mb-8 overflow-y-auto'>
          {loading ? (
            <Loading className='size-6 my-4' />
          ) : (
            collectionList.map((item) => (
              <div
                key={item.collection_id}
                className={cls(
                  'flex gap-2 border border-border rounded-md p-2 cursor-pointer relative h-[58px] flex-shrink-0',
                  selectedKeys.includes(item.collection_id)
                    ? 'border-[2px] border-border-active p-[7px]'
                    : '',
                )}
                onClick={() => handleClick(item.collection_id)}
              >
                {item.preview_urls?.[0] ? (
                  <img
                    className='size-10 rounded-sm object-cover'
                    src={item.preview_urls?.[0] || ''}
                    alt=''
                  />
                ) : (
                  <div className='size-10 rounded-sm flex justify-center items-center bg-surface-subdued'>
                    <IconFolder className='size-6 text-icon-subdued' />
                  </div>
                )}
                <div>
                  <div className='text-heading-sm font-bold tracking-15'>
                    {item.name}
                  </div>
                  <div>
                    <span className='mr-1 text-body-sm'>
                      {item?.number || 0} creation
                      {(item?.number || 0) > 1 ? 's' : ''}
                    </span>
                    {/* <span className='text-body-sm text-text-subdued'>
                    update {dayjs().diff(dayjs(item.update_time), 'day')} day
                    ago
                  </span> */}
                  </div>
                </div>
                <div
                  className={cls(
                    'absolute size-4 right-4 top-[50%] translate-y-[-50%]',
                    // selectedKeys.includes(item.collection_id)
                    //   ? 'block'
                    //   : 'hidden',
                  )}
                >
                  <Checkbox
                    checked={selectedKeys.includes(item.collection_id)}
                  />
                </div>
              </div>
            ))
          )}
        </div>
      </Dialog>
      <EditProjectDialog
        isEdit={false}
        open={addProjectOpen}
        editingCollection={null}
        onOpenChange={setAddProjectOpen}
        onCreateSuccess={createSuccess}
      />
    </>
  )
}

export default SelectCollectionDialog
